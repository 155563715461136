@import "login-form-from-messages-link.scss";

.g-recaptcha {
    width: fit-content;
    margin: 0 auto;
}

.intro-login {
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: calc(var(--nav-bar-height) + 20px);

    .login-form-wrap {
        background: var(--grey-light-basic);
        margin: 0 auto;
        box-shadow: 0 3px 6px #00000029;
        padding: 10px 10px 0;
        width: 285px;
    }

    .title {
        text-align: center;
        font-family: Poppins-Bold, sans-serif;
        h1 {
            font-size: 24px;
            line-height: 28px;
            margin: 0;
        }
    }

    .sign-up-form {
        display: flex;
        flex-direction: column-reverse;
        height: initial;
    }

    .form-wrapper {
        margin: 0 auto 10px;
        width: 100%;

        .form-group {
            margin: 0;
            p {
                margin: 0 0 10px;
                font-size: 14px;
            }

            .g-recaptcha {
                transform: scale(0.9);
                -webkit-transform: scale(0.88);
                transform-origin: 0 0;
                -webkit-transform-origin: 0 0;
            }
        }

        .login-form, .reset-form {
            position: relative;
            width: 100%;
            flex-direction: column;
            margin: 0;

            .login-form-alert-danger, .form-alert-danger {
                font-size: 12px;
                color: #e84042;
            }

            .form-alert-danger {
                font-size: 14px;
            }

            label {
                color: var(--blue-basic);
                font-size: 14px;
                line-height: 16px;
            }

            .form-control {
                min-height: 30px;
                font-size: 13px;
                line-height: 16px;
                font-family: Poppins, sans-serif;
                border: 1px solid #c7c7c7;
                border-radius: 0;
                background-color: var(--white);
                color: var(--blue-basic);
                text-overflow: ellipsis;
                width: 100%;
                padding: 8px 10px;
                cursor: pointer;
                &:-webkit-autofill {
                    -webkit-text-fill-color: var(--blue-basic);
                }
            }

            .submit-btn, .update-password {
                margin: 25px auto 20px;
                display: block;
                transition: background .4s, color .4s, border-color .4s;
                vertical-align: middle;
                text-align: center;
                text-decoration: none;
                font-size: 16px;
                font-family: Poppins, sans-serif;
                letter-spacing: 0;
                padding: 0;
                height: 35px;
                border-radius: 0;
                width: 100%;
                text-transform: uppercase;
                cursor: pointer;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
            }
        }

        .form-group-checkbox {
            .checkbox-form-control {
                display: none;
            }

            label {
                position: relative;
                left: 30px;
            }

            label::before {
                content: '';
                position: absolute;
                top: -2px;
                left: -30px;
                width: 18px;
                height: 18px;
                border: 1px solid var(--blue-basic);
            }

            label::after {
                font-size: 20px;
                font-weight: bold;
                position: absolute;
                top: -23px;
                left: -41px;
                display: none;
                width: 28px;
                height: 28px;
                content: '¬';
                transform: rotate(135deg);
                color: var(--blue-basic);
            }

            input[type='checkbox']:checked + label::after {
                display: block;
                margin-top: 18px;
            }
        }

        .user-setting-block {
            width: 70%;
            margin: 0 auto;

            .forgot-pass {
                width: 100%;
                text-align: center;
                margin-bottom: 10px;

                a {
                    font-size: 16px;
                    color: var(--blue-basic);

                    &:hover,
                    &:focus,
                    &:active {
                        color: var(--yellow-basic);
                    }
                }
            }
        }

        .login-with-backup-code {
            width: 100%;
            text-align: center;
            margin: 0 auto 10px;
            font-size: 13px;

            br {
                display: block;
            }

            .link {
                cursor: pointer;
                color: var(--blue-basic);

                &:hover,
                &:focus,
                &:active {
                    color: var(--yellow-basic);
                }
            }
        }
    }

    .third-party-auth {
        justify-content: center;
        margin: 0;
        text-align: center;

        &.registration-box {
            margin-top: 5px;
        }

        a {
            display: inline-block;
            color: var(--blue-basic);
            width: 100%;
            text-align: center;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;

            &:hover,
            &:focus,
            &:active {
                color: var(--yellow-basic);
            }

            &.button {
                border: 1px solid #c7c7c7;
                background: var(--white);
                height: 35px;
                font-size: 14px;
                line-height: 23px;
                position: relative;
                top: -2px;
                text-decoration: none;
                margin: 7px 0;
                padding: 5px;
            }

            svg {
                position: absolute;
                left: 30px;
            }

            &.facebook {
                text-align: left;
                padding-left: 59px;
                svg {
                    top: 4px;
                }
            }

            &.appstore {
                svg {
                    top: 2px;
                }
            }
        }
    }

    .registration {
        a {
            color: var(--blue-basic);
            font-size: 14px;

            &:hover,
            &:focus,
            &:active {
                color: var(--yellow-basic);
            }
        }
    }

    .login-form-alert-danger {
        color: var(--red-basic);
        margin-bottom: 5px;
        font-size: 11px;
    }

    .alert-danger {
        text-align: center;
        margin: 15px 0;
        font-size: 14px;
        color: var(--red-basic);
    }

    .form-alert-danger {
        font-size: 14px;
    }

    .grey-line {
        border: 0;
        border-top: 2px solid rgba(0, 0, 0, .1);
        width: 90%;
        margin: 1.3rem auto 0;
    }

    &.guests-registration {
        .form-wrapper {

            .login-form {
                h2 {
                    font-size: 17px;
                    margin: 12px 0;
                    text-align: center;
                }

                label {
                    color: var(--blue-basic);
                    font-size: 14px;
                    line-height: 16px;
                }

                .form-control {
                    min-height: 25px;
                    font-size: 12px;
                    line-height: 14px;
                }

                .submit-btn {
                    margin: 25px auto 20px;
                    height: 30px;
                }
            }

            .form-group-checkbox {

                label {
                    font-size: 11px;
                    line-height: 13px;
                }

                label::after {
                    top: -24px;
                    left: -42px;
                }
            }
        }

        .login-form-alert-danger, .form-alert-danger {
            color: var(--red-basic);
            font-size: 10px;
        }
    }

    &.reset-password {

        .form-wrapper {
            .form-group {
                p {
                    margin: 0 0 20px;
                }
            }

            .reset-form {
                h2 {
                    font-size: 17px;
                    margin: 15px 0 20px;
                    text-align: center;
                }

                label {
                    color: var(--blue-basic);
                    font-size: 14px;
                    line-height: 16px;
                }

                .form-control {
                    min-height: 40px;
                    font-size: 12px;
                    line-height: 14px;

                    &::placeholder {
                        font-size: 14px;
                        line-height: 16px;
                    }
                }

                .update-password {
                    margin: 35px auto 30px;
                    height: 40px;
                }
            }
        }
    }
}

@media screen and (min-width: 375px) {
    .intro-login {
        .login-form-wrap {
            padding-top: 15px;
        }

        .title {
            h1 {
                margin-bottom: 15px;
            }
        }
    }
}

@media screen and (min-width: 414px) {
    .intro-login {
        .login-form-wrap {
            padding-top: 20px;
        }

        .form-wrapper {
            .login-form {

                .submit-btn {
                    margin: 30px auto 25px;
                }
            }
        }

        .grey-line {

            margin: 1.7rem auto 0;
        }
    }
}

@media screen and (min-width: 768px) {
    .intro-login {
        margin-top: calc(var(--nav-bar-height) + 70px);

        .sign-up-form {
            flex-direction: column;
        }

        .grey-line {
            display: none;
        }

        br {
            display: none;
        }

        .form-wrapper {
            border-bottom: 2px solid rgba(0, 0, 0, .1);
            margin-bottom: 15px;

            .form-group {

                .g-recaptcha {
                    transform: initial;
                    -webkit-transform: initial;
                    transform-origin: initial;
                    -webkit-transform-origin: initial;
                }
            }

            .user-setting-block {

                .forgot-pass {
                    margin-bottom: 25px;
                }
            }
        }

        .third-party-auth {
            &.registration-box {
                margin-bottom: 15px;
            }

            a {
                font-size: 16px;
            }
        }

        &.guests-registration {
            .login-form-wrap {
                padding-top: 30px;
            }

            br {
                display: inline-block;
            }

            .form-wrapper {
                border-bottom: none;
            }
        }

        &.reset-password {
            .login-form-wrap {
                padding-top: 30px;
            }

            .form-wrapper {
                border-bottom: none;
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    .intro-login {

        .login-form-wrap {
            width: 465px;
        }

        .title {
            h1 {
                font-size: 26px;
            }
        }

        .sign-up-form {
            width: 350px;
            margin: 0 auto;
        }

        .third-party-auth {

            a {
                svg {
                    left: 73px;
                }

                &.facebook {
                    padding-left: 100px;
                }
            }
        }

        &.guests-registration {
            .login-form-wrap {
                padding-top: 20px;
            }

            .form-wrapper {
                .form-group-checkbox {
                    width: 90%;
                    text-align: left;

                    label {
                        font-size: 12px;
                        line-height: 14px;
                    }
                }
            }
        }

        &.reset-password {

            .form-wrapper {
                .reset-form {
                    h2 {
                        font-size: 18px;
                        margin: 15px 0 30px;
                        text-align: center;
                    }

                    label {
                        font-size: 16px;
                        line-height: 20px;
                    }

                    .update-password {
                        margin: 40px auto 30px;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1366px) {
    .intro-login {

        .login-form-wrap {
            padding: 20px 10px;
        }

        .form-wrapper {
            margin-bottom: 25px;

            .login-form {

                .form-control {
                    min-height: 35px;
                    font-size: 14px;
                }

                .submit-btn {
                    margin: 30px auto 25px;
                    height: 40px;
                }
            }
        }

        .third-party-auth {
            a {
                &.button {
                    height: 40px;
                    line-height: 27px;
                }

                svg {
                    top: 7px;
                }

                &.facebook {
                    svg {
                        top: 6px;
                    }
                }

                &.appstore {
                    svg {
                        top: 4px;
                    }
                }
            }
        }

        &.reset-password {
            .login-form-wrap {
                padding: 40px 10px 0;
            }
        }
    }
}