@import './common-variables.scss';

.login-view-messages-container {
    width: 90%;
    margin: 30px auto 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;

    .login-form-wrap {
        background: var(--grey-light-basic);
        margin: 0;
        box-shadow: 0 3px 6px #00000029;
        padding: 20px 10px 0;
    }

    .title {
        h1 {
            font-family: Poppins-Bold, sans-serif;
            font-size: 24px;
            line-height: 30px;
            margin-bottom: 15px;
            text-align: center;
        }
    }

    .sign-up-form {
        height: initial;

        .form-wrapper {
            margin: 0 auto 10px;
            width: 100%;

            .form-group {
                margin: 0 0 20px;
                position: relative;
                p {
                    margin: 0 0 10px;
                }
            }

            form {
                .form-title {
                    font-family: Poppins-Medium, sans-serif;
                    font-size: 19px;
                    line-height: 25px;
                    margin-bottom: 35px;
                    text-align: center;
                }

                .login-form-alert-danger, .alert-danger {
                    display: inline-block;
                    color: var(--red-basic);
                    margin-bottom: 5px;
                    font-size: 11px;
                }
                .alert-danger {
                    text-align: center;
                    width: 100%;
                }
                input[type='email'],
                input[type='password'],
                input[type='text'] {
                    min-height: 40px;
                    font-size: 16px;
                    line-height: 18px;
                    font-family: Poppins, sans-serif;
                    border: 1px solid #c7c7c7;
                    border-radius: 0;
                    background-color: var(--white);
                    color: var(--blue-basic);
                    text-overflow: ellipsis;
                    width: 100%;
                    padding: 8px 10px;
                    cursor: pointer;

                    &::placeholder {
                        color: #707070;
                    }
                }
            }

            input[type='submit'] {
                margin: 20px auto;
                display: block;
                transition: background .4s, color .4s, border-color .4s;
                vertical-align: middle;
                text-align: center;
                text-decoration: none;
                appearance: none;
                font-size: 16px;
                font-family: Poppins, sans-serif;
                letter-spacing: 0;
                padding: 0;
                height: 45px;
                border-radius: 0;
                width: 100%;
                text-transform: uppercase;
                cursor: pointer;
            }

            label {
                position: relative;
                font-family: Popins-Medium, sans-serif;
                font-size: 16px;
            }

            .show-symbols {
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                border: none;
                width: 30px;
                height: 40px;
                cursor: pointer;
                background: url($login-forms-sprite + '#icon-preview_blue') center no-repeat;
                position: absolute;
                top: 17px;
                right: 10px;
                padding: 0;

                &.active {
                    width: 23px;
                    background: url($login-forms-sprite + '#slash-icon-eye_blue') center no-repeat;
                }

                &:hover {
                    background: url($login-forms-sprite + '#icon-preview_yellow') center no-repeat;
                    &.active {
                        background: url($login-forms-sprite + '#slash-icon-eye_yellow') center no-repeat;
                    }
                }
            }
        }
    }

    .hidden {
        display: none;
    }

    .email-block,
    .password-block {
        display: flex;
        flex-direction: column;

        label {
            color: var(--blue-basic);
            font-size: 16px;
            font-family: Popins-Medium, sans-serif;
        }

        .img-block {
            width: 55px;
            height: 55px;
            margin: 10px auto;

            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    .form-group-checkbox {
        display: flex;
        position: relative;

        input {
            flex: 0 0 25px;
            border-radius: 0;
            border: 1px solid var(--blue-basic);
            width: 25px;
            height: 25px;
            margin-right: 15px;
            outline: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            margin-top: 8px;
        }

        label {
            font-family: Poppins, sans-serif;
            font-size: 16px;
            line-height: 21px;
            padding-right: 5px;
            flex: 0 1 auto;
        }

        input[type=checkbox]:checked+label::after {
            content: '';
            position: absolute;
            top: 13px;
            left: 6px;
            width: 14px;
            height: 14px;
            background: url($login-forms-sprite + '#icon-check_blue') center no-repeat;
        }
    }

    .terms-and-conditions {
        font-family: Poppins, sans-serif;
        color: #7E83A7;
        font-size: 14px;
        line-height: 18px;
        text-align: left;
    }

    .next-button, .send-form {
        font-size: 22px;
        font-family: Popins-Medium, sans-serif;
        margin-bottom: 45px;
        height: 45px;
        text-transform: uppercase;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }

    .back-btn {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: none;
        width: 30px;
        height: 20px;
        background: url($login-forms-sprite + '#icon-go-back-left-arrow_blue') center no-repeat;
        cursor: pointer;
        position: absolute;
        &:hover {
            background: url($login-forms-sprite + '#icon-go-back-left-arrow_yellow') center no-repeat;
        }
    }

    .password-block {
        position: relative;
        padding-top: 45px;
        .img-block {
            margin-top: 10px;
            width: 70px;
            height: 40px;
            margin-bottom: 5px;
        }

        .title {
            h1 {
                margin-top: 0;
            }
        }
        .form-group {
            margin-bottom: 0 !important;
        }
        input[type='submit'] {
            margin: 5px 0 !important;
        }
    }

    .hidden {
        display: none;
    }

    .forgot-pass {
        text-align: center;
        padding-bottom: 20px;

        a {
            color: var(--blue-basic);
        }
    }

    .user-completed {
        .title {
            h1 {
                margin-bottom: 20px;
            }
        }

        .email-block {
            .form-group {
                margin-bottom: 5px;
            }
        }
        .send-form {
            margin-top: 35px;
            .send-form {
                margin: 0;
            }
        }
    }
}

@media screen and (min-width: 375px) {
    .login-view-messages-container {

        .sign-up-form {

            .form-wrapper {
                form {

                    .form-title {
                        font-size: 20px;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 414px) {
    .login-view-messages-container {
        .login-form-wrap {
            padding: 20px 28px 0;
        }
        .password-block {
            padding-top: initial;
            .img-block {
                margin-bottom: 10px;
            }

            .form-group {
                margin-bottom: 20px;
            }

            input[type='submit'] {
                margin: 20px 0;
            }
        }
        .back-btn {
            left: -20px;
        }
    }
}

@media screen and (min-width: 768px) {
    .login-view-messages-container {
        margin-top: 0;
        .login-form-wrap {
            width: 460px;
            padding-bottom: 10px;
        }

        .title {
            h1 {
                font-size: 26px;
                margin-bottom: 20px;
            }
        }

        .sign-up-form {
            width: 316px;
            margin: 0 auto;
        }

        .form-group-checkbox {
            input[type=checkbox]:checked+label::after {
                left: -33px;
            }
        }
        .forgot-pass {
            padding-bottom: 40px;
        }

        .user-completed {
            margin-top: 20px;
        }

        .back-btn {
            top: -12px;
            left: -58px;
        }
    }
}

@media screen and (min-width: 1200px) {
    .login-view-messages-container {
        .login-form-wrap {
            width: 710px;
            padding: 40px 28px 20px;
        }

        .title {
            h1 {
                font-size: 24px;
                margin-bottom: 30px;
            }
        }

        .sign-up-form {
            width: 480px;

            .form-wrapper {

                form {

                    .form-title {
                        font-size: 19px;
                        margin-bottom: 45px;

                        br {
                            display: none;
                        }
                    }

                    input[type='email'],
                    input[type='password'],
                    input[type='text'] {
                        min-height: 50px;
                        line-height: 19px;
                    }
                }

                input[type='submit'] {
                    font-size: 22px;
                    margin-bottom: 45px;
                    height: 55px;
                }

                .show-symbols {
                    top: 24px;
                }
            }
        }

        .email-block {
            .img-block {
                width: 60px;
                height: 60px;
            }
        }

        .user-completed {
            .title {
                h1 {
                    margin-bottom: 45px;
                }
            }
            .send-form {
                margin-top: 45px !important;
            }
        }
        .back-btn {
            top: -25px;
            left: -100px;
        }
    }
}