@charset "UTF-8";
.login-view-messages-container {
  width: 90%;
  margin: 30px auto 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.login-view-messages-container .login-form-wrap {
  background: var(--grey-light-basic);
  margin: 0;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1607843137);
  padding: 20px 10px 0;
}
.login-view-messages-container .title h1 {
  font-family: Poppins-Bold, sans-serif;
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 15px;
  text-align: center;
}
.login-view-messages-container .sign-up-form {
  height: initial;
}
.login-view-messages-container .sign-up-form .form-wrapper {
  margin: 0 auto 10px;
  width: 100%;
}
.login-view-messages-container .sign-up-form .form-wrapper .form-group {
  margin: 0 0 20px;
  position: relative;
}
.login-view-messages-container .sign-up-form .form-wrapper .form-group p {
  margin: 0 0 10px;
}
.login-view-messages-container .sign-up-form .form-wrapper form .form-title {
  font-family: Poppins-Medium, sans-serif;
  font-size: 19px;
  line-height: 25px;
  margin-bottom: 35px;
  text-align: center;
}
.login-view-messages-container .sign-up-form .form-wrapper form .login-form-alert-danger, .login-view-messages-container .sign-up-form .form-wrapper form .alert-danger {
  display: inline-block;
  color: var(--red-basic);
  margin-bottom: 5px;
  font-size: 11px;
}
.login-view-messages-container .sign-up-form .form-wrapper form .alert-danger {
  text-align: center;
  width: 100%;
}
.login-view-messages-container .sign-up-form .form-wrapper form input[type=email],
.login-view-messages-container .sign-up-form .form-wrapper form input[type=password],
.login-view-messages-container .sign-up-form .form-wrapper form input[type=text] {
  min-height: 40px;
  font-size: 16px;
  line-height: 18px;
  font-family: Poppins, sans-serif;
  border: 1px solid #c7c7c7;
  border-radius: 0;
  background-color: var(--white);
  color: var(--blue-basic);
  text-overflow: ellipsis;
  width: 100%;
  padding: 8px 10px;
  cursor: pointer;
}
.login-view-messages-container .sign-up-form .form-wrapper form input[type=email]::placeholder,
.login-view-messages-container .sign-up-form .form-wrapper form input[type=password]::placeholder,
.login-view-messages-container .sign-up-form .form-wrapper form input[type=text]::placeholder {
  color: #707070;
}
.login-view-messages-container .sign-up-form .form-wrapper input[type=submit] {
  margin: 20px auto;
  display: block;
  transition: background 0.4s, color 0.4s, border-color 0.4s;
  vertical-align: middle;
  text-align: center;
  text-decoration: none;
  appearance: none;
  font-size: 16px;
  font-family: Poppins, sans-serif;
  letter-spacing: 0;
  padding: 0;
  height: 45px;
  border-radius: 0;
  width: 100%;
  text-transform: uppercase;
  cursor: pointer;
}
.login-view-messages-container .sign-up-form .form-wrapper label {
  position: relative;
  font-family: Popins-Medium, sans-serif;
  font-size: 16px;
}
.login-view-messages-container .sign-up-form .form-wrapper .show-symbols {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  width: 30px;
  height: 40px;
  cursor: pointer;
  background: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/login-forms-sprite-1182be56c1.svg#icon-preview_blue") center no-repeat;
  position: absolute;
  top: 17px;
  right: 10px;
  padding: 0;
}
.login-view-messages-container .sign-up-form .form-wrapper .show-symbols.active {
  width: 23px;
  background: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/login-forms-sprite-1182be56c1.svg#slash-icon-eye_blue") center no-repeat;
}
.login-view-messages-container .sign-up-form .form-wrapper .show-symbols:hover {
  background: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/login-forms-sprite-1182be56c1.svg#icon-preview_yellow") center no-repeat;
}
.login-view-messages-container .sign-up-form .form-wrapper .show-symbols:hover.active {
  background: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/login-forms-sprite-1182be56c1.svg#slash-icon-eye_yellow") center no-repeat;
}
.login-view-messages-container .hidden {
  display: none;
}
.login-view-messages-container .email-block,
.login-view-messages-container .password-block {
  display: flex;
  flex-direction: column;
}
.login-view-messages-container .email-block label,
.login-view-messages-container .password-block label {
  color: var(--blue-basic);
  font-size: 16px;
  font-family: Popins-Medium, sans-serif;
}
.login-view-messages-container .email-block .img-block,
.login-view-messages-container .password-block .img-block {
  width: 55px;
  height: 55px;
  margin: 10px auto;
}
.login-view-messages-container .email-block .img-block img,
.login-view-messages-container .password-block .img-block img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.login-view-messages-container .form-group-checkbox {
  display: flex;
  position: relative;
}
.login-view-messages-container .form-group-checkbox input {
  flex: 0 0 25px;
  border-radius: 0;
  border: 1px solid var(--blue-basic);
  width: 25px;
  height: 25px;
  margin-right: 15px;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin-top: 8px;
}
.login-view-messages-container .form-group-checkbox label {
  font-family: Poppins, sans-serif;
  font-size: 16px;
  line-height: 21px;
  padding-right: 5px;
  flex: 0 1 auto;
}
.login-view-messages-container .form-group-checkbox input[type=checkbox]:checked + label::after {
  content: "";
  position: absolute;
  top: 13px;
  left: 6px;
  width: 14px;
  height: 14px;
  background: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/login-forms-sprite-1182be56c1.svg#icon-check_blue") center no-repeat;
}
.login-view-messages-container .terms-and-conditions {
  font-family: Poppins, sans-serif;
  color: #7E83A7;
  font-size: 14px;
  line-height: 18px;
  text-align: left;
}
.login-view-messages-container .next-button, .login-view-messages-container .send-form {
  font-size: 22px;
  font-family: Popins-Medium, sans-serif;
  margin-bottom: 45px;
  height: 45px;
  text-transform: uppercase;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.login-view-messages-container .back-btn {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  width: 30px;
  height: 20px;
  background: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/login-forms-sprite-1182be56c1.svg#icon-go-back-left-arrow_blue") center no-repeat;
  cursor: pointer;
  position: absolute;
}
.login-view-messages-container .back-btn:hover {
  background: url("//d3snk3ydzk0o2b.cloudfront.net/theme/frontend/_assets/images/sprites/login-forms-sprite-1182be56c1.svg#icon-go-back-left-arrow_yellow") center no-repeat;
}
.login-view-messages-container .password-block {
  position: relative;
  padding-top: 45px;
}
.login-view-messages-container .password-block .img-block {
  margin-top: 10px;
  width: 70px;
  height: 40px;
  margin-bottom: 5px;
}
.login-view-messages-container .password-block .title h1 {
  margin-top: 0;
}
.login-view-messages-container .password-block .form-group {
  margin-bottom: 0 !important;
}
.login-view-messages-container .password-block input[type=submit] {
  margin: 5px 0 !important;
}
.login-view-messages-container .hidden {
  display: none;
}
.login-view-messages-container .forgot-pass {
  text-align: center;
  padding-bottom: 20px;
}
.login-view-messages-container .forgot-pass a {
  color: var(--blue-basic);
}
.login-view-messages-container .user-completed .title h1 {
  margin-bottom: 20px;
}
.login-view-messages-container .user-completed .email-block .form-group {
  margin-bottom: 5px;
}
.login-view-messages-container .user-completed .send-form {
  margin-top: 35px;
}
.login-view-messages-container .user-completed .send-form .send-form {
  margin: 0;
}

@media screen and (min-width: 375px) {
  .login-view-messages-container .sign-up-form .form-wrapper form .form-title {
    font-size: 20px;
  }
}
@media screen and (min-width: 414px) {
  .login-view-messages-container .login-form-wrap {
    padding: 20px 28px 0;
  }
  .login-view-messages-container .password-block {
    padding-top: initial;
  }
  .login-view-messages-container .password-block .img-block {
    margin-bottom: 10px;
  }
  .login-view-messages-container .password-block .form-group {
    margin-bottom: 20px;
  }
  .login-view-messages-container .password-block input[type=submit] {
    margin: 20px 0;
  }
  .login-view-messages-container .back-btn {
    left: -20px;
  }
}
@media screen and (min-width: 768px) {
  .login-view-messages-container {
    margin-top: 0;
  }
  .login-view-messages-container .login-form-wrap {
    width: 460px;
    padding-bottom: 10px;
  }
  .login-view-messages-container .title h1 {
    font-size: 26px;
    margin-bottom: 20px;
  }
  .login-view-messages-container .sign-up-form {
    width: 316px;
    margin: 0 auto;
  }
  .login-view-messages-container .form-group-checkbox input[type=checkbox]:checked + label::after {
    left: -33px;
  }
  .login-view-messages-container .forgot-pass {
    padding-bottom: 40px;
  }
  .login-view-messages-container .user-completed {
    margin-top: 20px;
  }
  .login-view-messages-container .back-btn {
    top: -12px;
    left: -58px;
  }
}
@media screen and (min-width: 1200px) {
  .login-view-messages-container .login-form-wrap {
    width: 710px;
    padding: 40px 28px 20px;
  }
  .login-view-messages-container .title h1 {
    font-size: 24px;
    margin-bottom: 30px;
  }
  .login-view-messages-container .sign-up-form {
    width: 480px;
  }
  .login-view-messages-container .sign-up-form .form-wrapper form .form-title {
    font-size: 19px;
    margin-bottom: 45px;
  }
  .login-view-messages-container .sign-up-form .form-wrapper form .form-title br {
    display: none;
  }
  .login-view-messages-container .sign-up-form .form-wrapper form input[type=email],
  .login-view-messages-container .sign-up-form .form-wrapper form input[type=password],
  .login-view-messages-container .sign-up-form .form-wrapper form input[type=text] {
    min-height: 50px;
    line-height: 19px;
  }
  .login-view-messages-container .sign-up-form .form-wrapper input[type=submit] {
    font-size: 22px;
    margin-bottom: 45px;
    height: 55px;
  }
  .login-view-messages-container .sign-up-form .form-wrapper .show-symbols {
    top: 24px;
  }
  .login-view-messages-container .email-block .img-block {
    width: 60px;
    height: 60px;
  }
  .login-view-messages-container .user-completed .title h1 {
    margin-bottom: 45px;
  }
  .login-view-messages-container .user-completed .send-form {
    margin-top: 45px !important;
  }
  .login-view-messages-container .back-btn {
    top: -25px;
    left: -100px;
  }
}
.g-recaptcha {
  width: fit-content;
  margin: 0 auto;
}

.intro-login {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: calc(var(--nav-bar-height) + 20px);
}
.intro-login .login-form-wrap {
  background: var(--grey-light-basic);
  margin: 0 auto;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1607843137);
  padding: 10px 10px 0;
  width: 285px;
}
.intro-login .title {
  text-align: center;
  font-family: Poppins-Bold, sans-serif;
}
.intro-login .title h1 {
  font-size: 24px;
  line-height: 28px;
  margin: 0;
}
.intro-login .sign-up-form {
  display: flex;
  flex-direction: column-reverse;
  height: initial;
}
.intro-login .form-wrapper {
  margin: 0 auto 10px;
  width: 100%;
}
.intro-login .form-wrapper .form-group {
  margin: 0;
}
.intro-login .form-wrapper .form-group p {
  margin: 0 0 10px;
  font-size: 14px;
}
.intro-login .form-wrapper .form-group .g-recaptcha {
  transform: scale(0.9);
  -webkit-transform: scale(0.88);
  transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
}
.intro-login .form-wrapper .login-form, .intro-login .form-wrapper .reset-form {
  position: relative;
  width: 100%;
  flex-direction: column;
  margin: 0;
}
.intro-login .form-wrapper .login-form .login-form-alert-danger, .intro-login .form-wrapper .login-form .form-alert-danger, .intro-login .form-wrapper .reset-form .login-form-alert-danger, .intro-login .form-wrapper .reset-form .form-alert-danger {
  font-size: 12px;
  color: #e84042;
}
.intro-login .form-wrapper .login-form .form-alert-danger, .intro-login .form-wrapper .reset-form .form-alert-danger {
  font-size: 14px;
}
.intro-login .form-wrapper .login-form label, .intro-login .form-wrapper .reset-form label {
  color: var(--blue-basic);
  font-size: 14px;
  line-height: 16px;
}
.intro-login .form-wrapper .login-form .form-control, .intro-login .form-wrapper .reset-form .form-control {
  min-height: 30px;
  font-size: 13px;
  line-height: 16px;
  font-family: Poppins, sans-serif;
  border: 1px solid #c7c7c7;
  border-radius: 0;
  background-color: var(--white);
  color: var(--blue-basic);
  text-overflow: ellipsis;
  width: 100%;
  padding: 8px 10px;
  cursor: pointer;
}
.intro-login .form-wrapper .login-form .form-control:-webkit-autofill, .intro-login .form-wrapper .reset-form .form-control:-webkit-autofill {
  -webkit-text-fill-color: var(--blue-basic);
}
.intro-login .form-wrapper .login-form .submit-btn, .intro-login .form-wrapper .login-form .update-password, .intro-login .form-wrapper .reset-form .submit-btn, .intro-login .form-wrapper .reset-form .update-password {
  margin: 25px auto 20px;
  display: block;
  transition: background 0.4s, color 0.4s, border-color 0.4s;
  vertical-align: middle;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  font-family: Poppins, sans-serif;
  letter-spacing: 0;
  padding: 0;
  height: 35px;
  border-radius: 0;
  width: 100%;
  text-transform: uppercase;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.intro-login .form-wrapper .form-group-checkbox .checkbox-form-control {
  display: none;
}
.intro-login .form-wrapper .form-group-checkbox label {
  position: relative;
  left: 30px;
}
.intro-login .form-wrapper .form-group-checkbox label::before {
  content: "";
  position: absolute;
  top: -2px;
  left: -30px;
  width: 18px;
  height: 18px;
  border: 1px solid var(--blue-basic);
}
.intro-login .form-wrapper .form-group-checkbox label::after {
  font-size: 20px;
  font-weight: bold;
  position: absolute;
  top: -23px;
  left: -41px;
  display: none;
  width: 28px;
  height: 28px;
  content: "¬";
  transform: rotate(135deg);
  color: var(--blue-basic);
}
.intro-login .form-wrapper .form-group-checkbox input[type=checkbox]:checked + label::after {
  display: block;
  margin-top: 18px;
}
.intro-login .form-wrapper .user-setting-block {
  width: 70%;
  margin: 0 auto;
}
.intro-login .form-wrapper .user-setting-block .forgot-pass {
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
}
.intro-login .form-wrapper .user-setting-block .forgot-pass a {
  font-size: 16px;
  color: var(--blue-basic);
}
.intro-login .form-wrapper .user-setting-block .forgot-pass a:hover, .intro-login .form-wrapper .user-setting-block .forgot-pass a:focus, .intro-login .form-wrapper .user-setting-block .forgot-pass a:active {
  color: var(--yellow-basic);
}
.intro-login .form-wrapper .login-with-backup-code {
  width: 100%;
  text-align: center;
  margin: 0 auto 10px;
  font-size: 13px;
}
.intro-login .form-wrapper .login-with-backup-code br {
  display: block;
}
.intro-login .form-wrapper .login-with-backup-code .link {
  cursor: pointer;
  color: var(--blue-basic);
}
.intro-login .form-wrapper .login-with-backup-code .link:hover, .intro-login .form-wrapper .login-with-backup-code .link:focus, .intro-login .form-wrapper .login-with-backup-code .link:active {
  color: var(--yellow-basic);
}
.intro-login .third-party-auth {
  justify-content: center;
  margin: 0;
  text-align: center;
}
.intro-login .third-party-auth.registration-box {
  margin-top: 5px;
}
.intro-login .third-party-auth a {
  display: inline-block;
  color: var(--blue-basic);
  width: 100%;
  text-align: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.intro-login .third-party-auth a:hover, .intro-login .third-party-auth a:focus, .intro-login .third-party-auth a:active {
  color: var(--yellow-basic);
}
.intro-login .third-party-auth a.button {
  border: 1px solid #c7c7c7;
  background: var(--white);
  height: 35px;
  font-size: 14px;
  line-height: 23px;
  position: relative;
  top: -2px;
  text-decoration: none;
  margin: 7px 0;
  padding: 5px;
}
.intro-login .third-party-auth a svg {
  position: absolute;
  left: 30px;
}
.intro-login .third-party-auth a.facebook {
  text-align: left;
  padding-left: 59px;
}
.intro-login .third-party-auth a.facebook svg {
  top: 4px;
}
.intro-login .third-party-auth a.appstore svg {
  top: 2px;
}
.intro-login .registration a {
  color: var(--blue-basic);
  font-size: 14px;
}
.intro-login .registration a:hover, .intro-login .registration a:focus, .intro-login .registration a:active {
  color: var(--yellow-basic);
}
.intro-login .login-form-alert-danger {
  color: var(--red-basic);
  margin-bottom: 5px;
  font-size: 11px;
}
.intro-login .alert-danger {
  text-align: center;
  margin: 15px 0;
  font-size: 14px;
  color: var(--red-basic);
}
.intro-login .form-alert-danger {
  font-size: 14px;
}
.intro-login .grey-line {
  border: 0;
  border-top: 2px solid rgba(0, 0, 0, 0.1);
  width: 90%;
  margin: 1.3rem auto 0;
}
.intro-login.guests-registration .form-wrapper .login-form h2 {
  font-size: 17px;
  margin: 12px 0;
  text-align: center;
}
.intro-login.guests-registration .form-wrapper .login-form label {
  color: var(--blue-basic);
  font-size: 14px;
  line-height: 16px;
}
.intro-login.guests-registration .form-wrapper .login-form .form-control {
  min-height: 25px;
  font-size: 12px;
  line-height: 14px;
}
.intro-login.guests-registration .form-wrapper .login-form .submit-btn {
  margin: 25px auto 20px;
  height: 30px;
}
.intro-login.guests-registration .form-wrapper .form-group-checkbox label {
  font-size: 11px;
  line-height: 13px;
}
.intro-login.guests-registration .form-wrapper .form-group-checkbox label::after {
  top: -24px;
  left: -42px;
}
.intro-login.guests-registration .login-form-alert-danger, .intro-login.guests-registration .form-alert-danger {
  color: var(--red-basic);
  font-size: 10px;
}
.intro-login.reset-password .form-wrapper .form-group p {
  margin: 0 0 20px;
}
.intro-login.reset-password .form-wrapper .reset-form h2 {
  font-size: 17px;
  margin: 15px 0 20px;
  text-align: center;
}
.intro-login.reset-password .form-wrapper .reset-form label {
  color: var(--blue-basic);
  font-size: 14px;
  line-height: 16px;
}
.intro-login.reset-password .form-wrapper .reset-form .form-control {
  min-height: 40px;
  font-size: 12px;
  line-height: 14px;
}
.intro-login.reset-password .form-wrapper .reset-form .form-control::placeholder {
  font-size: 14px;
  line-height: 16px;
}
.intro-login.reset-password .form-wrapper .reset-form .update-password {
  margin: 35px auto 30px;
  height: 40px;
}

@media screen and (min-width: 375px) {
  .intro-login .login-form-wrap {
    padding-top: 15px;
  }
  .intro-login .title h1 {
    margin-bottom: 15px;
  }
}
@media screen and (min-width: 414px) {
  .intro-login .login-form-wrap {
    padding-top: 20px;
  }
  .intro-login .form-wrapper .login-form .submit-btn {
    margin: 30px auto 25px;
  }
  .intro-login .grey-line {
    margin: 1.7rem auto 0;
  }
}
@media screen and (min-width: 768px) {
  .intro-login {
    margin-top: calc(var(--nav-bar-height) + 70px);
  }
  .intro-login .sign-up-form {
    flex-direction: column;
  }
  .intro-login .grey-line {
    display: none;
  }
  .intro-login br {
    display: none;
  }
  .intro-login .form-wrapper {
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 15px;
  }
  .intro-login .form-wrapper .form-group .g-recaptcha {
    transform: initial;
    -webkit-transform: initial;
    transform-origin: initial;
    -webkit-transform-origin: initial;
  }
  .intro-login .form-wrapper .user-setting-block .forgot-pass {
    margin-bottom: 25px;
  }
  .intro-login .third-party-auth.registration-box {
    margin-bottom: 15px;
  }
  .intro-login .third-party-auth a {
    font-size: 16px;
  }
  .intro-login.guests-registration .login-form-wrap {
    padding-top: 30px;
  }
  .intro-login.guests-registration br {
    display: inline-block;
  }
  .intro-login.guests-registration .form-wrapper {
    border-bottom: none;
  }
  .intro-login.reset-password .login-form-wrap {
    padding-top: 30px;
  }
  .intro-login.reset-password .form-wrapper {
    border-bottom: none;
  }
}
@media screen and (min-width: 1024px) {
  .intro-login .login-form-wrap {
    width: 465px;
  }
  .intro-login .title h1 {
    font-size: 26px;
  }
  .intro-login .sign-up-form {
    width: 350px;
    margin: 0 auto;
  }
  .intro-login .third-party-auth a svg {
    left: 73px;
  }
  .intro-login .third-party-auth a.facebook {
    padding-left: 100px;
  }
  .intro-login.guests-registration .login-form-wrap {
    padding-top: 20px;
  }
  .intro-login.guests-registration .form-wrapper .form-group-checkbox {
    width: 90%;
    text-align: left;
  }
  .intro-login.guests-registration .form-wrapper .form-group-checkbox label {
    font-size: 12px;
    line-height: 14px;
  }
  .intro-login.reset-password .form-wrapper .reset-form h2 {
    font-size: 18px;
    margin: 15px 0 30px;
    text-align: center;
  }
  .intro-login.reset-password .form-wrapper .reset-form label {
    font-size: 16px;
    line-height: 20px;
  }
  .intro-login.reset-password .form-wrapper .reset-form .update-password {
    margin: 40px auto 30px;
  }
}
@media screen and (min-width: 1366px) {
  .intro-login .login-form-wrap {
    padding: 20px 10px;
  }
  .intro-login .form-wrapper {
    margin-bottom: 25px;
  }
  .intro-login .form-wrapper .login-form .form-control {
    min-height: 35px;
    font-size: 14px;
  }
  .intro-login .form-wrapper .login-form .submit-btn {
    margin: 30px auto 25px;
    height: 40px;
  }
  .intro-login .third-party-auth a.button {
    height: 40px;
    line-height: 27px;
  }
  .intro-login .third-party-auth a svg {
    top: 7px;
  }
  .intro-login .third-party-auth a.facebook svg {
    top: 6px;
  }
  .intro-login .third-party-auth a.appstore svg {
    top: 4px;
  }
  .intro-login.reset-password .login-form-wrap {
    padding: 40px 10px 0;
  }
}